@import "../_variables.scss";

.footer_bg {
  background-color: $color_dark;
  color: white;
}

.footer {
  padding: 0 0 3vh 0;
  font-size: 0.8rem;

  @media #{$media_sm} {
    padding: 0 !important;

    div{
      margin: 0 !important;
    }
  }

  @media #{$media_sm} {
    img{
      width: 50%;
      height: auto;
      margin: auto;
      display: block;
    }
    
  }
}

.cr {
  text-align: center;
}

.info {
  //position: absolute;
  font-size: 0.7rem;
  margin: 5vh 0 0 0;
  //padding-top: 5vh;
  //	margin-top: auto;

  p {
    margin: 0;
  }
  .break {
    margin-top: 2vh;
  }
  a {
    color: white;
  }
}

.bu_info{
  @media #{$media_sm} {
    padding: 1vh 8vw !important;
    margin: 0vh 8vw !important;
    height: auto;
    div{
      padding: .5vh 2vw;
    }
  }

  div{
    margin-bottom: 2vh;
    div{
      margin: 0;
    }
  }
}

.info_other{
	position: absolute;
  top: 0;
  
  @media #{$media_sm} {
    position: initial;

    padding: 1vh 8vw !important;
    margin: 0 !important;
  }
}

.info_sky {
	position: absolute;
	bottom: 0;
  //margin-top: auto;

  @media #{$media_sm} {
    position: initial;

    padding: 1vh 8vw !important;
    margin: 0 !important;
    
  }
}
