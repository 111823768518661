@import "../_variables.scss";

.nav_section {
  background-color: $color_dark;
  color: white;

	font-family: $main_font;
	font-weight: 400;
	font-size: 0.9rem;
  a {
		color: white !important;

		margin: 0 2rem 0 0;
	}
	margin: 0 0 0 0;
	padding: 0;

	

	.links {
		@media #{$media_sm} {
			display: none;
		}
	}

	.navbar-brand{
		padding: 1vh 4vw 1vh 2vw;
		font-family: $accent_font;
		font-size: 1.5rem;
		font-weight: 400;
		text-transform: uppercase;
		background: $color_main;
		img{
			margin: 0 1vw;
			position: relative;
			top: -2px;
			@media #{$media_sm} {
				height: 5vh;
			}
		}
	}
}
