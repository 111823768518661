@import "../variables";

.ap_hero {
  padding: 12vh 6vw;
  //@at-root position: relative;
  //left: -px;

  background-color: $color_light_accent;
  background-position: top right;
  color: $color_white_shade;

  @media #{$media_sm} {
    background-position: 70% 10%;
    background-size: auto 140% !important;
  }
}

.hero_info {
  margin: 0vh;
  text-align: left;
  display: table-cell;
  //height: 30vh;
  //padding: 10px;
  vertical-align: middle;

  h1 {
    // padding-top: 20vh;
  }

  img {
    width: 18vw;
    @media #{$media_sm} {
      height: 30vh;
      width: auto;
    }
  }

  .hero_pointer {
    font-size: 4rem;
  }
}

.ap_problem {
  padding: 0 0 5vh 0;
  background-color: $color_dark_shade;

  color: $color_dark;
  h4 {
    color: $color_dark_accent;
    text-transform: capitalize;
  }

  .intro {
    padding: 5vh 0;
    margin: 0;
    text-align: center;

    @media #{$media_sm} {
      padding: 5vh 4vw;
    }

    .line {
      margin: 1vh auto 2vh auto;
      width: 13vw;
      @media #{$media_sm} {
        width: 60vw;
      }
    }

    p {
      text-align: left;
    }
  }

  .problemlist {
    div {
      @media #{$media_sm} {
        margin: 0 !important;
        padding: 0 0 1vh 0 !important;
        text-align: left;
      }
    }
    .left {
      padding: 0 2vw 0 15px;
      text-align: right;
      h4 {
        margin-left: auto;
      }

      .icon {
        margin: auto;
      }
    }

    .right {
      padding: 0 15px 0 2vw;
    }

    .lastproblem {
      text-align: center;
      @media #{$media_sm} {
        margin: 0;
        padding: 0;
        text-align: left;
      }
    }
  }
}

#services {
  top: -50px;
  position: relative;
}

.ap_service_exp {
  padding: 5vh 0;
  background-color: $color_light_accent;

  .services_desc {
    @media #{$media_sm} {
      padding: 0 5vw;
    }
  }

  .provide_cont {
    // color: $color_light_accent;
    // background-color: $color_light_shade;

    .provide {
      margin: 5vh -15px;
      height: auto;

      @media #{$media_sm} {
        margin: 0;
        height: auto;
        padding: 0 5vw;
      }

      .provide_c {
        height: auto;

        @media #{$media_sm} {
          margin: 0;
          height: auto;

          h4 {
            font-size: 1.4rem;
          }
        }

        .provide_img {
          //height: auto;
          position: relative;
          height: 40vh;
          width: 100%;
          object-fit: cover;
        }
      }

      .provide_col {
        padding: 4vh 0vw;

        .line {
          // background-color: white;
          width: 15vw;
        }

        h5 {
          font-family: $main_font;
          font-weight: 100;
          padding-bottom: 3vh;
        }
      }

      //margin-bottom: 5vh;
      // h3.title {
      //   padding-top: 15vh;
      // }

      // p {
      //   padding-bottom: 10vh;
      // }
    }
  }

  @media #{$media_sm} {
    h3 {
      font-size: 2rem;
    }
  }
}

.services_group {
  padding: 5vh 0 0 0;

  // .card {
  //   border: none;

  //   .card-title {
  //     font-family: $accent_font;
  //     font-weight: 900;
  //   }
  // }
}

div.cardgroups.card-columns div.card {
  flex-direction: row;
  border: none;
  display: flex;

  .card-img {
    width: 20%;
  }
}

.ap_aboutus {
  // padding: 10vh 0;
  color: $color_dark;
  background-color: $color_dark_shade;

  //margin-top: 5vh;
  .about_desc {
    @media #{$media_sm} {
      padding: 0 10vw !important;
      // margin: 0 !important
      h1 {
        padding-top: 4vh !important;
      }
    }

    color: $color_dark_accent;
    background-color: $color_dark_shade;

    //margin-bottom: 5vh;
    h1.title {
      // color: $color_light_shade;
      padding-top: 15vh;
      text-transform: uppercase;
      // font-size: 4rem;
    }

    p {
      padding: 2vh 0;
    }

    .end {
      padding: 6vh;
    }
  }

  .about_image_col {
    background-color: black;
  }

  .about_stats {
    background-color: $color_main;
    color: $color_white_shade;
    padding: 2vh 0;

    .line_container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .horizontal_line {
      width: 0.1vw;
      height: 10vh;
      background-color: $color_white_shade;
    }

    text-align: center;
    h1 {
      font-size: 2rem;
    }

    h3 {
      font-family: $main_font;
      font-weight: 500;
      font-size: 1.2rem;
    }
  }

  .people_cards {
    padding: 10vh 0;
    text-align: center;
    .title {
      padding: 0 0 5vh 0;
    }

    .line {
      margin: 0 auto;
      top: -5vh;
      position: relative;
      width: 20vw;
    }
  }

  .persons {
    text-align: left;
  }

  .card_title {
    padding: 2vh 0 1vh 0;
    margin: auto;
    text-align: center;
  }

  .card_subtitle {
    padding: 0 0 1vh 0;
    margin: auto;
    text-align: center;
  }

  .social {
    text-align: center;
  }
}

.ap_client {
  // margin: 10vh 0;

  color: $color_dark;
  background-color: white;

  @media #{$media_sm} {
    padding: 0 8vw !important;
    margin: 0 !important;
    
  }

  .client_text {
    //color: white;
    padding: 8vh 0;
    .client_desc {
      margin: 0;
    }

    .client_example {
      margin: 0;
      .carousel {
        .block {
          width: 40vw;
          height: 50vh;
          margin: auto auto;
          padding: 2vh 2vw;
          // background-color: $color_white_shade;
          color: $color_dark;
          align-items: center;
          .img_div {
            img {
              width: 100%;
            }
          }

          @media #{$media_sm} {
            padding: 0;
            height: 60vh;
            width: auto;
          }
        }
      }
      h1 {
        //padding-top: 5vh;
        margin: auto;
        font-family: $accent_font;
        font-size: 1.8rem;
      }
      p {
        padding: 2vh 0;
      }
    }
  }
}

.ap_contact {
  padding: 7vh 0;

  @media #{$media_sm} {
    padding: 0 8vw !important;
    margin: 0 !important;
    
  }

  background-color: $color_dark_shade;

  .line {
    margin: 0 0 3vh 0;
  }

  .form_info {
    padding: 3vh 0;

    .questionaire {
      p {
        padding-right: 5vw;
      }
    }

    label {
      font-family: $accent_font;
      font-weight: 900;
    }

    .sb_button {
      background-color: $color_main;
      border-color: none;
      border-radius: 0;
      border: none;
      font-family: $main_font;
      font-weight: 500;
    }
  }
}

#contact_form {
  width: 90%;
}

.form-control {
  border-radius: 0;
}

.line {
  margin: 1vh 0 2vh 0;
  width: 10vw;
  height: 1vh;
  // z-index: -1;
  // position: relative;
  // top: -10vh;
  // left: 2%;
  background-color: $color_main;
}
