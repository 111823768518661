@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

$color_main: #438cca;
$color_dark: #1d272f;

$color_dark_accent: #242931;
$color_dark_shade: #e7eaec;

$color_light_shade: #6a788f;
$color_light_accent: white;

$color_white_shade: white;

//Fonts
$accent_font: "Montserrat", sans-serif;
$main_font: 'Lora', serif;


// media
$media_xxl: "(min-width: 1920px)";
$media_xl: "(max-width: 1700px)"; //large monitor
$media_lg: "(max-width: 1199px)"; //small monitor
$media_md: "(max-width: 991px)"; //tablet
$media_sm: "(max-width: 767px)"; //mobilr
